export const ConsultancyMessageType = {
    /**
     * 图文消息
     */
    TEXT: 0,

    /**
     * 问卷
     */
    QUESTIONNAIRE: 1,

    /**
     *  报告
     */
    REPORT: 2,

    /**
     * 处方
     */
    PRESCRIPTION: 3,

    /**
     * 干预方案
     */
    SCHEME: 4,

    /**
     * 打卡
     */
    PUNCH: 5,

    /**
     * 咨询结束
     */
    CONSULTANCY_END: 6,

    /**
     * 推荐干预方案
     */
    SCHEME_RECOMMEND: 7,

}

function defaultConsultancyMessage() {
    return {
        id: null,
        sender: null,
        type: ConsultancyMessageType.TEXT,
        atId: null,
        atShortMessage: null,
        time: new Date(),
        recalled: false,
        recallTime: null,
        message: null,
        images: [],
    }
}

const ConsultancyUtils = {
    ConsultancyMessageType: ConsultancyMessageType,
    defaultConsultancyMessage: defaultConsultancyMessage,
    IMAGE_MAX_COUNT: 20,
}

export default ConsultancyUtils;