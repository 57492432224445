var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"margin-vertical-large",staticStyle:{"margin-top":"40px"}},[(_vm.showTime && _vm.timeStr)?_c('div',{staticClass:"font-align-center"},[_c('span',{staticClass:"message-time font-size-extra-small"},[_vm._v(_vm._s(_vm.timeStr))])]):_vm._e(),_c('div',{staticClass:"consultancy-message margin-top",class:[
        'message-direct__' + _vm.direct
    ]},[(_vm.direct === 'from')?_c('div',{staticClass:"avatar"},[_c('user-avatar',{attrs:{"user":_vm.message.sender}})],1):_vm._e(),(_vm.message.type === _vm.ConsultancyMessageType.TEXT && !_vm.onlyImage)?_c('message-text',{staticClass:"message-content margin-horizontal",attrs:{"direct":_vm.direct,"message":_vm.message.message}}):(_vm.message.type === _vm.ConsultancyMessageType.SCHEME_RECOMMEND)?_c('message-scheme',{staticClass:"message-content margin-horizontal",attrs:{"direct":_vm.direct,"scheme":_vm.message.scheme}}):_vm._e(),(_vm.direct === 'to')?_c('div',{staticClass:"avatar"},[_c('user-avatar',{attrs:{"user":_vm.message.sender}})],1):_vm._e()],1),(_vm.message.images && _vm.message.images.length > 0)?_c('div',{staticClass:"image-list layout-vertical margin-top",class:[
        'message-direct__' + _vm.direct,
        {
          'layout-right': _vm.direct === 'to',
          'layout-left': _vm.direct === 'from',
          'is-only-images': _vm.onlyImage,
        }
    ]},_vm._l((_vm.message.images),function(image,index){return _c('div',{key:((image.id) + "#" + index),staticClass:"image margin-top",class:[
             'message-direct__' + _vm.direct ],staticStyle:{"max-width":"calc(70% - 400px)"},on:{"click":function($event){return _vm.handleImageClick(index)}}},[_c('el-image',{staticStyle:{"display":"block"},attrs:{"src":_vm.$prepareImageUrl(image)}})],1)}),0):_vm._e(),_c('ys-image-preview',{attrs:{"image-list":_vm.previewImageList,"current-image-index":_vm.currentPreviewImageIndex,"visible":_vm.imagePreviewVisible},on:{"update:currentImageIndex":function($event){_vm.currentPreviewImageIndex=$event},"update:current-image-index":function($event){_vm.currentPreviewImageIndex=$event},"update:visible":function($event){_vm.imagePreviewVisible=$event}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }