<template>
  <div class="consultancy-message-wrapper">
    <consultancy-message
        :message="message"
        :direct="direct"
        :show-time="showTime"
        v-if="message.type === ConsultancyMessageType.TEXT || message.type === ConsultancyMessageType.SCHEME_RECOMMEND">
    </consultancy-message>
    <consultancy-end
        :message="message"
        v-else-if="message.type === ConsultancyMessageType.CONSULTANCY_END"
    ></consultancy-end>
  </div>
</template>

<script>
import ConsultancyMessage from "@/pages/consultancy/basic/ConsultancyMessage";
import {ConsultancyMessageType} from "@/assets/javascript/consultancy-utils";
import ConsultancyEnd from "@/pages/consultancy/basic/ConsultancyEnd";

export default {
  name: "ConsultancyMessageWrapper",
  components: {ConsultancyEnd, ConsultancyMessage},
  props: {
    message: Object,
    direct: String,
    showTime: Boolean,
  },
  data() {
    return {
      ConsultancyMessageType,
    }
  }
}
</script>

<style scoped>

</style>