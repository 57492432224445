<template>
  <div class="text-message" style="margin-left: 10px;">
    <div
         :class="[
             'bubble__wrapper',
             'bubble-direct__' + direct,
         ]"
      >
      <div class="bubble padding padding-horizontal-large font-align-justify"
      >
        <span class="font-size-medium">{{ message }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MessageText",
  props: {
    message: {
      type: String,
      default: '消息内容'
    },
    direct: {
      type: String,
      default: 'from',
    }
  }
}
</script>

<style scoped>

.text-message {
  display: inline-block;
}


.bubble {
  border-radius: 20px;
  display: inline-block;
  vertical-align: bottom;
  min-height: 42px;
  box-sizing: border-box;
  cursor: pointer;
}

.bubble__wrapper.bubble-direct__to  .bubble {
  background-color: #f091a6;
  color: white;
  border-bottom-right-radius: 0px;
  filter: drop-shadow(0 0 2px #f091a6);
}

.bubble__wrapper.bubble-direct__from .bubble {
  background-color: white;
  color: #7d7c7c;
  border-bottom-left-radius: 0px;
  filter: drop-shadow(0 0 2px #bec0c0);


}

</style>