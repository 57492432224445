<template>
  <div class="consultancy layout-vertical" style="height: 100%;">
    <div class="loading-container layout-self-center" v-if="isLoadingBefore">
      <div class="loading-before">
        <i class="el-icon-loading"></i>
      </div>
    </div>
    <div class="layout-flexible message-window layout-horizontal layout-center" style="overflow: hidden">
      <ys-infinite-scroll-view
          ref="messageScrollView"
          class="message-scroll-view"
          listen-reach-edge
          @reach:top="handleMessageScrollViewReachTop"
          @reach:bottom="handleMessageScrollViewReachBottom"
      >
        <div
            class="padding padding-large"
            ref="messageList"
        >
          <consultancy-message-wrapper
              ref="messages"
              v-for="(message, index) in messageList"
              :key="message" :message="message"
              :direct="message.sender.id === consultancyList[0].account.id ? 'from' : 'to'"
              :data-id="message.id"
              :show-time="index === 0 ||
               messageList[index].time - messageList[index - 1].time > 5 * 60 * 1000 ||
               messageList[index].consultancyId != messageList[index - 1].consultancyId
              "
          >

          </consultancy-message-wrapper>
        </div>
      </ys-infinite-scroll-view>
    </div>
    <div class="input-panel layout-inflexible layout-vertical"
         :class="{
          'is-focused': isFocused,
       }"
    >
      <div
          class="operations padding-horizontal-large layout-horizontal layout-middle font-size-extra-large font-color-secondary">
        <el-tooltip class="layout-inflexible" content="发送图片" :open-delay="500">
          <div class="btn-operation padding" @click.stop="handleSendImageClick"><i class="fas fa-camera"></i></div>
        </el-tooltip>
        <el-tooltip class="layout-inflexible" content="推荐干预方案" :open-delay="500">
          <div class="btn-operation padding"
               @click.stop="handleSendSchemeClick"
          >
            <i class="fas fa-arrow-circle-up"></i>
          </div>
        </el-tooltip>
        <el-tooltip class="layout-inflexible" content="结束咨询" :open-delay="500">
          <div
              class="btn-operation padding"
              :class="{
                'is-disable': !lastConsultancy || lastConsultancy.state !== BusinessState.STARTED
              }"
              @click.stop="handleStopConsultancyClick">
            <i class="far fa-stop-circle font-color-danger"></i>
          </div>
        </el-tooltip>
        <div class="layout-flexible"></div>
        <template v-if="false">
          <div class="margin-left font-size-medium layout-inflexible">
            <span>该用户还有</span>
            <span class="font-color-danger" v-if="freedomTimes < 2">{{ freedomTimes }}</span>
            <span class="font-color-primary" v-else>{{ freedomTimes }}</span>
            <span>次咨询机会</span>
          </div>
          <ys-button class="margin-left layout-inflexible" type="secondary" lighting
                     @click="handleAddFreedomTimesClick">
            赠送咨询机会
          </ys-button>
        </template>
      </div>
      <ys-textarea ref="input" :show-background="false" class="font-size-medium"
                   @keyup.ctrl.enter.native="handleSendMessageClick"
                   @focus="handleInputFocus"
                   @blur="handleInputBlur"
                   v-model="messageInputted"></ys-textarea>
      <ys-button class="layout-self-right margin-right-large margin-bottom-large" type="secondary" lighting
                 @click="handleSendMessageClick">发送
      </ys-button>
      <ys-image-picker
          class="image-picker"
          ref="imagePicker"
          :image.sync="pickedImage"
          @update:image="handleImageChange"
      ></ys-image-picker>
    </div>
    <delete-dialog
        :visible.sync="stopRemindDialogVisible"
        message="您确定要结束本次咨询吗？"
        confirm-button-text="结束"
        @confirm="handleStopConsultancyConfirmClick"
    ></delete-dialog>
    <delete-dialog
        :visible.sync="addFreedomTimesRemindVisible"
        message="赠送给该用户5次免费咨询机会？"
        confirm-button-text="赠送"
        @confirm="handleAddFreedomTimesConfirm"
    ></delete-dialog>
    <scheme-picker-dialog
        :organization-id="organizationId"
        :visible.sync="schemePickerDialogVisible"
        :max-selected-count="1"
        on-shelves
        @pick="handleSchemePicked"
    ></scheme-picker-dialog>
  </div>
</template>

<script>
import YsTextarea from "@/components/wedigets/YsTextarea";
import YsButton from "@/components/wedigets/YsButton";
import YsInfiniteScrollView from "@/components/wedigets/YsInfiniteScrollView";
import httpapi, {RequestType} from "@/assets/javascript/httpapi";
import ConsultancyMessageWrapper from "@/pages/consultancy/basic/ConsultancyMessageWrapper";
import TextUtils from "@/assets/javascript/text-utils";
import YsImagePicker from "@/components/wedigets/YsImagePicker";
import DeleteDialog from "@/components/dialog/DeleteDialog";
import BusinessState from "@/assets/javascript/business-state";
import SchemePickerDialog from "@/components/dialog/SchemePickerDialog";

function doLoadConsultancy() {
  if (this.loadingCode === this.busyLoadingCode) return;
  let loadingCode = this.loadingCode;
  this.busyLoadingCode = loadingCode;
  this.$reqGet({
    path: '/consultancy/get/by/account/organization',
    data: {
      accountId: this.accountId,
      organizationId: this.organizationId,
    }
  }).then(res => {
    if (loadingCode !== this.loadingCode) return;
    if (res.data) {
      this.$appendAfter(this.consultancyList, [res.data]);
      this.busyLoadingCode = 0;
      doLoadConsultancyMessageBefore.bind(this)();
      doLoadFreedomTimes.bind(this)();
    }
  }).catch(() => {
    if (loadingCode !== this.loadingCode) return;
    this.$message.error('加载失败');
  }).complete(() => {
    if (loadingCode !== this.loadingCode) return;
    this.busyLoadingCode = 0;
  })
}

function doLoadConsultancyMessageBefore(pageSize = 20) {
  if (this.consultancyList.length === 0) return;
  if (this.loadingCode === this.busyLoadingCode) return;
  let loadingCode = this.loadingCode;
  this.busyLoadingCode = this.loadingCode;
  let firstConsultancy = this.consultancyList[0];
  let firstMessage = this.messageList.length > 0 ? this.messageList[0] : null;
  this.isLoadingBefore = true;
  this.$reqGet({
    path: '/consultancy/message/previous/list/get',
    data: {
      consultancyId: firstConsultancy.id,
      pageSize,
      last: firstMessage && firstMessage.consultancyId === firstConsultancy.id ? firstMessage.id : null,
    }
  }).then(res => {
    if (loadingCode !== this.loadingCode) return;
    let isMessageListEmpty = this.messageList.length === 0;
    this.previousFirstMessageId = this.messageList.length > 0 ? this.messageList[0].id : null;
    this.$appendBefore(this.messageList, res.data);
    if (isMessageListEmpty) {
      setTimeout(() => {
        this.$refs.messageScrollView.scrollToBottom()
      }, 500);
    } else {
      this.keepScrollPosition();
    }

    if (res.data.length < pageSize) {
      this.busyLoadingCode = 0;
      doLoadPreviousConsultancy.bind(this)(pageSize - res.data.length);
    }
  }).catch(() => {
    if (loadingCode !== this.loadingCode) return;
    this.$message.error('加载失败');
  }).complete(() => {
    if (loadingCode !== this.loadingCode) return;
    this.busyLoadingCode = 0;
    setTimeout(() => this.isLoadingBefore = false, 500);
  })
}

function doLoadConsultancyMessageAfter(pageSize = 20, scrollToNewest = false) {
  if (this.consultancyList.length === 0) return;
  if (this.loadingCode === this.busyLoadingCode) return;
  let loadingCode = this.loadingCode;
  this.busyLoadingCode = loadingCode;
  let lastConsultancy = this.consultancyList[this.consultancyList.length - 1];
  let lastMessage = this.messageList.length > 0 ? this.messageList[this.messageList.length - 1] : null;
  this.$reqGet({
    path: '/consultancy/message/next/list/get',
    data: {
      consultancyId: lastConsultancy.id,
      pageSize,
      first: lastMessage && lastMessage.consultancyId === lastConsultancy.id ? lastMessage.id : null,
    }
  }).then(res => {
    if (loadingCode !== this.loadingCode) return;
    this.$appendAfter(this.messageList, res.data);
    if (res.data.length < pageSize) {
      this.busyLoadingCode = 0;
      doLoadNextConsultancy.bind(this)(pageSize - res.data.length);
    }
    doMarkMessageReaded.bind(this)();
    if (scrollToNewest) {
      this.scrollToNewest();
    }
  }).catch(() => {
    if (loadingCode !== this.loadingCode) return;
    this.$message.error('加载失败');
  }).complete(() => {
    if (loadingCode !== this.loadingCode) return;
    this.busyLoadingCode = 0;
  })
}

function doLoadPreviousConsultancy(messagePageSize = 0) {
  if (this.consultancyList.length === 0) return;
  if (this.loadingCode === this.busyLoadingCode) return;
  let loadingCode = this.loadingCode;
  this.busyLoadingCode = loadingCode;
  this.$reqGet({
    path: '/consultancy/get/previous',
    data: {
      consultancyId: this.consultancyList[0].id,
    }
  }).then(res => {
    if (loadingCode !== this.loadingCode) return;
    if (res.data) {
      this.$appendBefore(this.consultancyList, [res.data]);
      if (messagePageSize > 0) {
        this.busyLoadingCode = 0;
        doLoadConsultancyMessageBefore.bind(this)(messagePageSize);
      }
    }
  }).catch(() => {
    if (loadingCode !== this.loadingCode) return;
    this.$message.error('加载失败');
  }).complete(() => {
    if (loadingCode !== this.loadingCode) return;
    this.busyLoadingCode = 0;
  })
}

function doLoadNextConsultancy(messagePageSize) {
  if (this.consultancyList.length === 0) return;
  if (this.loadingCode === this.busyLoadingCode) return;
  let loadingCode = this.loadingCode;
  this.busyLoadingCode = loadingCode;
  this.$reqGet({
    path: '/consultancy/get/next',
    data: {
      consultancyId: this.consultancyList[this.consultancyList.length - 1].id,
    }
  }).then(res => {
    if (loadingCode !== this.loadingCode) return;
    if (res.data) {
      this.$appendAfter(this.consultancyList, [res.data]);
      doLoadFreedomTimes.bind(this)();
      if (messagePageSize > 0) {
        this.busyLoadingCode = 0;
        doLoadConsultancyMessageAfter.bind(this)(messagePageSize);
      }
    }
  }).catch(() => {
    if (loadingCode !== this.loadingCode) return;
    this.$message.error('加载失败');
  }).complete(() => {
    if (loadingCode !== this.loadingCode) return;
    this.busyLoadingCode = 0;
  })
}

function doSendTextMessage(message, images) {
  if (this.loadingCode === this.busyLoadingCode) return;
  let loadingCode = this.loadingCode;
  this.busyLoadingCode = loadingCode;
  this.$reqPost({
    path: '/consultancy/message/text/send/to/account',
    type: RequestType.X_WWW_FORM_URLENCODED,
    data: {
      accountId: this.accountId,
      organizationId: this.organizationId,
      message,
      images,
    }
  }).then(() => {
    if (loadingCode !== this.loadingCode) return;
    this.busyLoadingCode = 0;
    this.messageInputted = null;
    this.pickedImage = null;
    doLoadConsultancyMessageAfter.bind(this)(20, true);
  }).catch(() => {
    if (loadingCode !== this.loadingCode) return;
    this.$message.error('发送失败');
  }).complete(() => {
    if (loadingCode !== this.loadingCode) return;
    this.busyLoadingCode = 0;
  })
}

function doSendSchemeMessage(schemeId) {
  if (this.loadingCode === this.busyLoadingCode) return;
  this.busyLoadingCode = this.loadingCode;
  this.$reqPost({
    path: `/consultancy/message/scheme/recommend/${this.accountId}/${this.organizationId}/${schemeId}`
  })
      .returnWhenCodeMatches()
      .then(() => {
        this.busyLoadingCode = 0;
        this.$message.success('干预方案已发送');
        doLoadConsultancyMessageAfter.bind(this)(20, true);
      })
      .catch(() => {
        this.$message.error('发送失败');
      }).complete(() => {
    this.busyLoadingCode = 0;
  })
}

function doStopConsultancy() {
  if (this.loadingCode === this.busyLoadingCode) return;
  let loadingCode = this.loadingCode;
  this.busyLoadingCode = loadingCode;
  let lastConsultancy = this.consultancyList[this.consultancyList.length - 1];
  this.$reqPost({
    path: '/consultancy/stop',
    data: {
      id: lastConsultancy.id,
    }
  }).then(() => {
    if (loadingCode !== this.loadingCode) return;
    lastConsultancy.state = BusinessState.STOPPED;
    this.busyLoadingCode = 0;
    this.loadMessageListAfter(true);
    doLoadFreedomTimes.bind(this)();
  }).catch(() => {
    if (loadingCode !== this.loadingCode) return;
    this.$message.error('操作失败');
  }).complete(() => {
    if (loadingCode !== this.loadingCode) return;
    this.busyLoadingCode = 0;
  })
}

function doLoadFreedomTimes() {
  let loadingCode = this.loadingCode;
  this.$reqGet({
    path: '/consultancy/freedom/times/get/by_organization',
    data: {
      accountId: this.accountId,
      organizationId: this.organizationId,
    }
  }).then(res => {
    if (loadingCode !== this.loadingCode) return;
    this.freedomTimes = res.data;
  }).catch(() => {
    if (loadingCode !== this.loadingCode) return;
    this.$message.error('加载失败');
  }).complete(() => {
    if (loadingCode !== this.loadingCode) return;
    this.busyLoadingCode = 0;
  })
}

function doMarkMessageReaded() {
  this.$reqPost({
    path: '/consultancy/read/set/by_organization_member',
    data: {
      organizationId: this.organizationId,
      accountId: this.accountId,
    }
  })
      .then(() => {
        window.eventBus.$emit('notify:message', {setread: true});
      })
}

function doAddFreedomTimes() {
  if (this.loadingCode === this.busyLoadingCode) {
    this.$message.warning('操作频繁，请稍后再试');
    return;
  }
  let loadingCode = this.loadingCode;
  this.busyLoadingCode = loadingCode;
  this.$reqPost({
    path: '/consultancy/freedom/times/add',
    data: {
      accountId: this.accountId,
      organizationId: this.organizationId,
      count: 5,
    }
  }).then(() => {
    if (loadingCode !== this.loadingCode) return;
    this.freedomTimes += 5;
    this.busyLoadingCode = 0;
    doLoadFreedomTimes.bind(this)();
  }).catch(() => {
    if (loadingCode !== this.loadingCode) return;
    this.$message.error('操作失败');
  }).complete(() => {
    if (loadingCode !== this.loadingCode) return;
    this.busyLoadingCode = 0;
  })
}

export default {
  name: "Consultancy",
  mixins: [httpapi],
  components: {
    SchemePickerDialog,
    DeleteDialog,
    YsImagePicker, ConsultancyMessageWrapper, YsInfiniteScrollView, YsButton, YsTextarea
  },
  props: {
    accountId: Number,
    organizationId: Number,
  },
  data() {
    return {
      BusinessState,

      loadingCode: 1,
      busyLoadingCode: 0,

      isFocused: false,

      consultancyList: [],
      messageList: [],
      freedomTimes: 0,

      isLoadingBefore: false,

      previousFirstMessageId: null,

      messageInputted: null,
      pickedImage: null,

      stopRemindDialogVisible: false,

      addFreedomTimesRemindVisible: false,

      /**
       * 发送干预方案
       */
      schemePickerDialogVisible: false,

    }
  },
  computed: {
    inputParams() {
      const {accountId, organizationId} = this;
      return {
        accountId,
        organizationId
      }
    },
    lastConsultancy() {
      return this.consultancyList.length > 0 ? this.consultancyList[this.consultancyList.length - 1] : null;
    }
  },
  watch: {
    inputParams: {
      handler() {
        this.loadingCode++;
        this.consultancyList = [];
        this.messageList = [];
        this.isLoadingBefore = false;
        this.previousFirstMessageId = null;
        if (this.accountId && this.organizationId) {
          this.loadConsultancy();
        }
      },
      immediate: true,
    }
  },
  methods: {
    loadConsultancy() {
      doLoadConsultancy.bind(this)();
    },
    loadMessageListBefore() {
      doLoadConsultancyMessageBefore.bind(this)();
    },
    loadMessageListAfter(scrollToNewest = false) {
      doLoadConsultancyMessageAfter.bind(this)(20, scrollToNewest);
    },
    sendTextMessage() {
      if (TextUtils.isBlank(this.messageInputted) && !this.pickedImage) {
        return;
      }
      doSendTextMessage.bind(this)(this.messageInputted, this.pickedImage ? [this.pickedImage] : []);
    },
    handleInputPanelClick(e) {
      this.$refs.input.focus();
      e.preventDefault();
    },

    handleInputFocus() {
      this.isFocused = true;
    },
    handleInputBlur() {
      this.isFocused = false;
    },
    handleMessageScrollViewReachTop() {
      this.loadMessageListBefore();
    },
    handleMessageScrollViewReachBottom() {
      this.loadMessageListAfter();
    },
    handleSendMessageClick() {
      if (TextUtils.isBlank(this.messageInputted)) {
        this.$message.warning('请输入消息内容');
        return;
      }
      this.sendTextMessage();
    },
    handleSendImageClick() {
      this.$refs.imagePicker.$el.click();
    },
    handleSendSchemeClick() {
      this.schemePickerDialogVisible = true;
    },
    handleSchemePicked(schemeList) {
      doSendSchemeMessage.bind(this)(schemeList[0].id);
    },
    handleImageChange() {
      if (this.pickedImage && !this.pickedImage?.__localImageFile) {
        this.messageInputted = null;
        this.sendTextMessage();
      }
    },
    handleStopConsultancyClick() {
      if (this.lastConsultancy?.state === BusinessState.STARTED) {
        this.stopRemindDialogVisible = true;
      }
    },
    handleStopConsultancyConfirmClick() {
      doStopConsultancy.bind(this)();
    },
    handleNewMessageNotified(event) {
      if (!event?.setread) {
        this.loadMessageListAfter(true);
      }
    },
    handleAddFreedomTimesClick() {
      this.addFreedomTimesRemindVisible = true;
    },
    handleAddFreedomTimesConfirm() {
      if (this.accountId && this.organizationId) {
        doAddFreedomTimes.bind(this)();
      }
    },
    getMessageViewById(id) {
      for (let messageView of this.$refs.messages) {
        if (messageView.message.id === id) {
          return messageView;
        }
      }
      return null;
    },
    scrollToNewest() {
      setTimeout(() => this.$refs.messageScrollView.smoothScrollToBottom(), 300);
    },
    keepScrollPosition() {
      if (!this.previousFirstMessageId) return;
      let messageView = this.getMessageViewById(this.previousFirstMessageId);
      if (!messageView) return;
      let previousScrollBottom = messageView.$el.getBoundingClientRect().bottom
      this.$nextTick(() => {
        let currentScrollBottom = messageView.$el.getBoundingClientRect().bottom
        let scrollTop = this.$refs.messageScrollView.getScrollTop() + currentScrollBottom - previousScrollBottom;
        this.$refs.messageScrollView.scrollTo(scrollTop);
      })
    }
  },
  activated() {
    setTimeout(() => {
      this.$refs.messageScrollView.scrollToBottom();
    }, 500);
  },
  mounted() {
    window.eventBus.$on('notify:message', this.handleNewMessageNotified);
  },
  destroyed() {
    window.eventBus.$off('notify:message', this.handleNewMessageNotified);
  }
}
</script>

<style scoped>

.consultancy {
  background-color: #f1f1f1;
}

.message-window {
  border-left: 1px solid #dadada;
  border-right: 1px solid #dadada;
}

.message-scroll-view {
  height: 100%;
  width: 100%;
}

.input-panel {
  background-color: #f1f1f1;
  min-height: 100px;
  box-sizing: border-box;
  border-top: 1px solid #dadada;
  border-left: 1px solid #dadada;
  border-right: 1px solid #dadada;
}

.input-panel.is-focused {
  background-color: white;
}

.btn-operation {
  cursor: pointer;
}

.btn-operation:hover {
  filter: brightness(.95);
}

.btn-operation.is-disable {
  opacity: .5;
}

.loading-container {
  height: 0px;
  text-align: center;
}

.loading-before {
  width: 32px;
  height: 32px;
  line-height: 32px;
  background-color: white;
  border-radius: 50%;
  box-shadow: 0 0 4px #bec0c0;
  position: relative;
  top: 20px;
}

.image-picker {
  width: 0px;
  height: 0px;
}


</style>