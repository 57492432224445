<template>
  <div class="margin-vertical-large" style="margin-top: 40px;">
    <div class="font-align-center" v-if="showTime && timeStr">
      <span class="message-time font-size-extra-small">{{ timeStr }}</span>
    </div>
    <div
        class="consultancy-message margin-top"
        :class="[
          'message-direct__' + direct
      ]"
    >
      <div
          v-if="direct === 'from'"
          class="avatar">
        <user-avatar :user="message.sender"></user-avatar>
      </div>
      <message-text :direct="direct" :message="message.message" class="message-content margin-horizontal"
                    v-if="message.type === ConsultancyMessageType.TEXT && !onlyImage"
      ></message-text>
      <message-scheme :direct="direct" :scheme="message.scheme" class="message-content margin-horizontal"
                      v-else-if="message.type === ConsultancyMessageType.SCHEME_RECOMMEND"
      >
      </message-scheme>
      <div
          v-if="direct === 'to'"
          class="avatar">
        <user-avatar :user="message.sender"></user-avatar>
      </div>
    </div>
    <div class="image-list layout-vertical margin-top"
         :class="[
          'message-direct__' + direct,
          {
            'layout-right': direct === 'to',
            'layout-left': direct === 'from',
            'is-only-images': onlyImage,
          }
      ]"
         v-if="message.images && message.images.length > 0"
    >

      <div class="image margin-top"
           :class="[
               'message-direct__' + direct,
           ]"
           style="max-width: calc(70% - 400px)"
           v-for="(image, index) in message.images" :key="`${image.id}#${index}`"
           @click="handleImageClick(index)"
      >
        <el-image style="display: block" :src="$prepareImageUrl(image)"></el-image>
      </div>
    </div>
    <ys-image-preview :image-list="previewImageList" :current-image-index.sync="currentPreviewImageIndex"
                      :visible.sync="imagePreviewVisible"></ys-image-preview>
  </div>
</template>

<script>
import MessageText from "@/pages/consultancy/basic/MessageText";
import UserAvatar from "@/components/user/UserAvatar";
import httpapi from "@/assets/javascript/httpapi";
import YsImagePreview from "@/components/wedigets/YsImagePreviewer";
import {TimeUtils} from "@/assets/javascript/time-utils";
import {ConsultancyMessageType} from "@/assets/javascript/consultancy-utils";
import MessageScheme from "@/pages/consultancy/basic/MessageScheme";

export default {
  name: "ConsultancyMessage",
  mixins: [httpapi],
  components: {MessageScheme, YsImagePreview, UserAvatar, MessageText},
  props: {
    message: Object,
    direct: {
      type: String,
      default: 'to',
    },
    showTime: Boolean,
  },
  computed: {
    onlyImage() {
      return !this.message.message
    },
    previewImageList() {
      return this.message && this.message.images ? this.message.images : [];
    },
    timeStr() {
      if (this.message) {
        return TimeUtils.formatNormal(this.message.time);
      } else {
        return null;
      }
    }
  },
  data() {
    return {
      ConsultancyMessageType: ConsultancyMessageType,

      imagePreviewVisible: false,
      currentPreviewImageIndex: 0,
    }
  },
  methods: {
    handleImageClick(index) {
      this.currentPreviewImageIndex = index;
      this.imagePreviewVisible = true;
    }
  }
}
</script>

<style scoped>

.avatar {
  display: inline-block;
  vertical-align: bottom;
  position: relative;
  top: 10px;
}

.message-time {
  background-color: #dadada;
  padding: 4px 8px;
  color: #7d7c7c;
  border-radius: 100px;
}

.consultancy-message.message-direct__to {
  text-align: right;
}

.consultancy-message.message-direct__from {
  text-align: left;
}

.image-list {
  position: relative;
}

.image-list.is-only-images {
  top: -48px;
}

.image {
  border-radius: 10px;
  overflow: hidden;
  min-width: 100px;
}

.image.message-direct__to {
  background-color: #f091a6;
  box-sizing: border-box;
  box-shadow: 0 0 4px #f091a6;
  margin-right: 56px;
}

.image.message-direct__from {
  background-color: white;
  box-sizing: border-box;
  box-shadow: 0 0 4px #bec0c0;
  margin-left: 56px;
}

.message-content {
  max-width: calc(100% - 200px)
}

</style>