<template>
  <div class=" font-align-center">
    <span class="consultancy-end font-size-extra-small">
      咨询已结束
      <span v-if="timeStr">:{{timeStr}}</span>
    </span>
  </div>
</template>

<script>
import {TimeUtils} from "@/assets/javascript/time-utils";

export default {
  name: "ConsultancyEnd",
  props: {
    message: Object,
  },
  computed: {
    timeStr() {
      return this.message ? TimeUtils.formatNormal(this.message.consultancy.endTime) : null;
    }
  }
}
</script>

<style scoped>

.consultancy-end {
  padding: 4px 8px;
  background-color: #f0c78a;
  color: #ff5500;
  line-height: 18px;
  border-radius: 100px;
}

</style>