<template>

  <div class="scheme-message" style="margin-left: 10px;">
    <div
        :class="[
             'bubble__wrapper',
             'bubble-direct__' + direct,
         ]"
    >
      <div class="bubble font-align-justify"
           :class="{
            'is-active': scheme && scheme.commodity && scheme.commodity.state === CommodityState.ON_SHELVES
           }"
      >
        <div class="padding-horizontal-large padding-top">
          <template v-if="scheme">
            <div><i class="fas fa-link"></i><span class="font-size-medium margin-left-small">{{ scheme.title }}</span></div>
            <div v-if="!scheme.commodity || scheme.commodity.state !== CommodityState.ON_SHELVES"><i
                class="fas fa-times"></i> 商品未上架
            </div>
            <div v-else>
              <div v-if="originalCostStr" class="font-size-small"
                   style="text-decoration: line-through; font-style: italic">
                原价：￥{{ originalCostStr }}
              </div>
              <div class="font-size-small">
                <span v-if="costStr">￥{{ costStr }}</span>
                <span v-else>免费</span>
              </div>
            </div>
          </template>
          <div v-else><i class="fas fa-times"></i> 链接失效</div>
        </div>
        <div class="footer font-size-extra-small padding-horizontal-large margin-top">
          <span>营养干预方案</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import httpapi from "@/assets/javascript/httpapi";
import CommodityUtils, {CommodityState} from "@/assets/javascript/commodity-utils";
import ExactNumber from "@/assets/javascript/exact-number";

export default {
  name: "MessageScheme",
  mixins: [httpapi],
  props: {
    scheme: {
      type: Object,
      observer: '_schemeChange'
    },
    direct: {
      type: String,
      default: 'from',
    }
  },
  data() {
    return {
      CommodityUtils: CommodityUtils,
      CommodityState: CommodityState,

      costStr: null,
      originalCostStr: null,
    }
  },
  watch: {
    scheme: {
      handler(scheme) {
        this.costStr = ExactNumber.stringify(scheme?.cost);
        this.originalCostStr = ExactNumber.stringify(scheme?.originalCostStr);
      },
      immediate: true,
    }
  }
}
</script>

<style scoped>

.scheme-message {
  display: inline-block;
}


.bubble {
  border-radius: 20px;
  display: inline-block;
  vertical-align: bottom;
  min-height: 42px;
  box-sizing: border-box;
  cursor: pointer;
}

.bubble__wrapper.bubble-direct__to .bubble {
  background-color: #faf4f5;
  color: #bec0c0;
  border-bottom-right-radius: 0px;
  filter: drop-shadow(0 0 2px #dadada);
}

.bubble__wrapper.bubble-direct__to .bubble.is-active {
  background-color: #f091a6;
  color: white;
  border-bottom-right-radius: 0px;
  filter: drop-shadow(0 0 2px #f091a6);
}

.bubble__wrapper.bubble-direct__from .bubble {
  background-color: white;
  color: #7d7c7c;
  border-bottom-left-radius: 0px;
  filter: drop-shadow(0 0 2px #bec0c0);
}

.footer {
  background-color: rgba(255, 255, 255, .15);
  padding-top: 4px;
  padding-bottom: 4px;
}

</style>